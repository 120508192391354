import React from "react";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import { get } from "lodash";

function TableHead({ header }) {
  return (
    <thead>
      <tr>
        {header.map((header, index) => {
          return (
            <th style={{ textAlign: header.align }} scope="col" key={index}>
              {header.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

const ShiftTable = ({ header, shifts, openUpdateForm, handleDelete }) => {
  const renderContent = (shift, index) => {
    console.log(`shift`, shift);
    return (
      <>
        {shift.schedules.map((schedule, i) => (
          <tr key={index}>
            <td style={{ textAlign: "left" }}>
              {get(schedule.service, "name", "")}
            </td>
            <td style={{ textAlign: "left" }}>{schedule.day}</td>
            <td style={{ textAlign: "left" }}>{schedule.name}</td>
            <td style={{ textAlign: "center" }}>
              ${schedule.price} - ${schedule.actual_price}
            </td>
            <td style={{ textAlign: "left" }}>
              {moment(schedule.start_time, "hh:mm:ss").format("hh:mm A")} -{" "}
              {moment(schedule.end_time, "hh:mm:ss").format("hh:mm A")}
            </td>
            <td style={{ textAlign: "left" }}>
              {schedule.status ? "Active" : "Inactive"}
            </td>
            <td>
              <div style={{ minWidth: 150, justifyContent: "center" }}>
                <EditIcon
                  onClick={() => {
                    openUpdateForm && openUpdateForm(schedule);
                  }}
                  color="action"
                  style={{ cursor: "pointer", margin: 2 }}
                />
                <DeleteForeverIcon
                  onClick={() => {
                    handleDelete && handleDelete(schedule);
                  }}
                  color="action"
                  style={{ cursor: "pointer", margin: 2 }}
                />
              </div>
            </td>
          </tr>
        ))}
      </>
    );
  };

  return (
    <table className="table">
      <TableHead header={header} />
      <tbody>
        {/*{shifts.monday.map((shift, index) => renderContent(shift, index))}
        {shifts.tuesday.map((shift, index) => renderContent(shift, index))}
        {shifts.wednesday.map((shift, index) => renderContent(shift, index))}
        {shifts.thursday.map((shift, index) => renderContent(shift, index))}
        {shifts.friday.map((shift, index) => renderContent(shift, index))}
        {shifts.saturday.map((shift, index) => renderContent(shift, index))}
        {shifts.sunday.map((shift, index) => renderContent(shift, index))}*/}
        {shifts.map((shift, index) => renderContent(shift, index))}
      </tbody>
    </table>
  );
};
export default ShiftTable;
