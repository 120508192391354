import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { find, findIndex, get, isEmpty } from "lodash";
import moment from "moment";
import "../theme/css/invoice.css";
// import jsPDF from "jspdf";
// import "jspdf-autotable";
// import ReactDOMServer from "react-dom/server";
// import html2canvas from "html2canvas";
import Moment from "react-moment";
// import pdfLogo from "../assets/logo.png";

import AlertBar from "./core/Alert/AlertBar";
import InputText from "./core/InputText/InputText";
import InputSelect from "./core/InputSelect/InputSelect";
import CustomLoader from "./CustomLoader";

// Custom imports
import store from "../store";
import { fetchUserDetails } from "../services/UserService";
import { completeBooking } from "../services/private/BookingService";
import { fetchUser } from "../store/actions/userAction";

const InvoiceComponent = ({
  data,
  isAdmin,
  invoiceId,
  payAgain,
  goToDetails,
}) => {
  const pdfContent = useRef(null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertErrorMsg, setAlertErrorMsg] = useState("Something went wrong");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // console.log(`Invoice data => `, data);

    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);

  const invoicePaymentType = (data) => {
    if (!data) {
      return 0;
    }
    const isSuccess = findIndex(data, { payment_status: "SUCCESS" });
    if (isSuccess === -1) {
      return data[0].payment_type;
    } else {
      return data[isSuccess].payment_type;
    }
  };

  const invoiceStatus = (data) => {
    if (!data) {
      return 0;
    }
    // console.log(data);
    if (get(data, "cancel_type", false)) {
      if (data.status === "CANCELLING") {
        return "Request to cancel";
      } else {
        return data.status;
      }
    } else {
      if (isEmpty(find(data.payments, { payment_status: "SUCCESS" }))) {
        return "UNPAID";
      } else {
        return "PAID";
      }
    }
  };

  // const gotoPayment = () => {
  //   payAgain(data);
  // };

  const paymentObject = () => {
    const obj = find(data.payments, { payment_status: "SUCCESS" });
    if (isEmpty(obj)) {
      return data.payments[data.payments.length - 1];
    } else {
      return obj;
    }
  };

  const formik = useFormik({
    initialValues: {
      photograperName: get("", "photograperName", data.photographer_name),
      shootType: get("", "shootType", ""),
      notes: get("", "notes", ""),
      payment_type: get("", "payment_type", "Online"),
      pay_with: "",
    },
    validationSchema: Yup.object({
      photograperName: Yup.string()
        .min(4, "name must be at least 4 characters")
        .required("Required"),
      // shootType: Yup.string().required("Required"),
    }),

    onSubmit: async (values) => {
      console.log(`values`, values);

      if (values.shootType === "" || values.shootType === "default") {
        Swal.fire({
          title: "Error",
          text: "You must select a shoot type!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#f53535",
          confirmButtonText: "Okay",
        });

        return;
      }

      if (values.payment_type === "" || values.payment_type === "default") {
        Swal.fire({
          title: "Error",
          text: "You must select a payment method!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#f53535",
          confirmButtonText: "Okay",
        });

        return;
      }

      if (values.pay_with === "" || values.pay_with === "default") {
        Swal.fire({
          title: "Error",
          text: "You must select a card to pay!",
          icon: "warning",
          showCancelButton: false,
          confirmButtonColor: "#f53535",
          confirmButtonText: "Okay",
        });

        return;
      }

      // console.log(`values`, values);

      // return;

      setIsLoading(true);

      let payload = {
        ...data,
        photographer_name: values.photograperName,
        shoot_type: values.shootType,
        notes: values.notes,
        payment_type: values.payment_type,
        pay_with: values.pay_with.replace(/\*/g, ""),
      };

      // console.log(`payload =>`, { ...payload });
      // return;
      payAgain(payload)
        .then((res) => {
          fetchUserDetails().then((res) => {
            // update user state
            store.dispatch(fetchUser(res));

            // this will trigger a artisan call in backend to send invoice email
            completeBooking();

            // updating use details after booking making role permission issue in booking history
            setTimeout(() => {
              setIsLoading(false);
              window.location.reload();
            }, 1500);
          });
        })
        .catch((error) => {
          console.log(`error`, error);
          console.log(`Error => `, error.response.data.message);
          setIsLoading(false);
          setAlertErrorMsg(error.response.data.data);
          setAlertOpen(true);
        });
    },
  });

  const shootTypes = [
    { label: "Boudoir", value: "boudoir" },
    { label: "Fashion", value: "fashion" },
    { label: "Family", value: "family" },
    { label: "Newborn", value: "newborn" },
    { label: "Other", value: "other" },
    { label: "Portrait", value: "portrait" },
    { label: "Wedding", value: "wedding" },
  ];

  const paymentTypes = [
    { label: "Cash", value: "Cash" },
    { label: "Cheque", value: "Cheque" },
    { label: "Credit Card", value: "Credit Card" },
    { label: "Debit Card", value: "Debit Card" },
    { label: "E-Transfer", value: "E-Transfer" },
  ];

  let cards = [];

  if (data.cards.length > 0) {
    cards = data.cards.map((card) => {
      if (card.default_card && !formik.values.pay_with) {
        formik.setFieldValue("pay_with", "************" + card.last_digits);
      }

      return {
        label: "************" + card.last_digits,
        value: "************" + card.last_digits,
      };
    });
  }

  function goTo() {
    console.log(`go to`, data.user);
  }

  return (
    <div className="container">
      <div className="page-content container px-5">
        <div className="page-header text-blue-d2">
          <h1 className="page-title text-secondary-d1">
            Invoice
            <small className="page-info pl-2">
              <i className="fa fa-angle-double-right text-80 mr-2"></i>
              {/*  ID: {invoiceId}*/}
              {invoiceId}
            </small>
          </h1>

          <div className="page-tools">
            <div className="action-buttons">
              <a
                href={`${process.env.REACT_APP_API}/storage/documents/pdf/${invoiceId}.pdf`}
                onClick={() => console.log(`download starting. . .`)}
                className="btn bg-white btn-light mx-1px text-95"
                data-title="PDF"
                download
              >
                <i className="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                Export
              </a>
            </div>
          </div>
        </div>

        <div className="px-0" ref={pdfContent}>
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-sm-6">
                  <div>
                    <span className="text-sm text-grey-m2 align-middle mr-2">
                      To:
                    </span>
                    <span
                      className="text-600 text-110 text-blue align-middle"
                      style={{ cursor: "pointer" }}
                      onClick={() => goToDetails(data.user)}
                    >
                      {get(data, "user.firstname", "")}{" "}
                      {get(data, "user.lastname", "")}
                    </span>
                  </div>
                  <div className="text-grey-m2">
                    {/* <div className="my-1">{get(data, "user.lastname", "")}</div> */}
                    <div className="my-1">{get(data, "user.email", "")}</div>
                    <div className="my-1">
                      <i className="fa fa-phone fa-flip-horizontal text-secondary"></i>{" "}
                      <b className="text-600">{get(data, "user.phone", "")}</b>
                    </div>
                  </div>
                </div>
                {/*// <!-- /.col -->*/}

                <div className="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                  <hr className="d-sm-none" />
                  <div className="text-grey-m2">
                    <div className="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                      Invoice
                    </div>

                    <div className="my-2">
                      <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                      <span className="text-600 text-90">ID:</span>{" "}
                      {get(data, "id", "")}
                    </div>

                    <div className="my-2">
                      <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                      <span className="text-600 text-90">Booked by: </span>
                      {data.payments[data.payments.length - 1].booked_by}
                    </div>

                    <div className="my-2">
                      <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                      <span className="text-600 text-90">Payment: </span>
                      {invoicePaymentType(get(data, "payments", null))}
                    </div>

                    <div className="my-2">
                      <i className="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                      <span className="text-600 text-90">Status:</span>{" "}
                      {invoiceStatus(data) === "UNPAID" ? (
                        <span className="badge badge-warning badge-pill px-25">
                          <small className="font-weight-bold">
                            {invoiceStatus(data)}
                          </small>
                        </span>
                      ) : (
                        <span className="badge badge-success badge-pill px-25">
                          <small className="font-weight-bold">
                            {invoiceStatus(data)}
                          </small>
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                {/*// <!-- /.col -->*/}
              </div>

              <div className="mt-4">
                <div className="row text-600 text-white bgc-default-tp1 py-25">
                  <div className="d-none d-sm-block col-1">#</div>
                  <div className="col-4">Service name</div>
                  <div className="col-5">Time</div>
                  <div style={{ textAlign: "right" }} className="col-2">
                    Unit Price($)
                  </div>
                </div>

                <div className="text-95 text-secondary-d3">
                  {!data.manually_created ? (
                    <>
                      {data.slots_details.map((slot, index) => {
                        return (
                          <div className="row mb-2 mb-sm-0 py-25" key={index}>
                            <div className="d-none d-sm-block col-1">
                              {index + 1}
                            </div>
                            <div className="col-4">{slot.service.name}</div>
                            <div className="col-5">
                              <Moment format="YYYY-MM-DD hh:mm:A">
                                {moment(slot.slot, "YYYY-MM-DD hh:mm:ss")}
                              </Moment>
                            </div>
                            <div
                              className="d-none d-sm-block col-2 text-95"
                              style={{ textAlign: "right" }}
                            >
                              ${get(slot, "price", 0).toFixed(2)}
                            </div>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {data.payments[0].items.map((item, index) => (
                        <div className="row mb-2 mb-sm-0 py-25" key={index}>
                          <div className="d-none d-sm-block col-1">
                            {index + 1}
                          </div>
                          <div className="col-4">{item.description}</div>
                          <div className="col-5"></div>
                          <div
                            className="d-none d-sm-block col-2 text-95"
                            style={{ textAlign: "right" }}
                          >
                            ${item.cost}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </div>

                <div className="row border-b-2 brc-default-l2"></div>

                <div className="row mt-3">
                  <div className="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                    {/*Extra note such as company or payment information...*/}
                  </div>

                  <div className="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                    <div className="row my-2">
                      <div className="col-7 text-right">SubTotal</div>
                      <div className="col-5" style={{ textAlign: "right" }}>
                        <span className="text-120 text-secondary-d1">
                          {get(paymentObject(), "sub_total", 0).toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-7 text-right">Coupon discount</div>
                      <div className="col-5" style={{ textAlign: "right" }}>
                        <span className="text-120 text-secondary-d1">
                          {get(paymentObject(), "coupon_discount", 0).toFixed(
                            2
                          )}
                        </span>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-7 text-right">Used points</div>
                      <div className="col-5" style={{ textAlign: "right" }}>
                        <span className="text-120 text-secondary-d1">
                          {get(paymentObject(), "used_points", 0).toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-7 text-right">Total discount</div>
                      <div className="col-5" style={{ textAlign: "right" }}>
                        <span className="text-120 text-secondary-d1">
                          {get(paymentObject(), "discount", 0).toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <div className="row my-2">
                      <div className="col-7 text-right">Total Tax ($)</div>
                      <div className="col-5" style={{ textAlign: "right" }}>
                        <span className="text-110 text-secondary-d1">
                          {get(paymentObject(), "tax_amount", 0).toFixed(2)}
                        </span>
                      </div>
                    </div>

                    <div className="row my-2 align-items-center bgc-primary-l3 p-2">
                      <div className="col-7 text-right">Total Amount</div>
                      <div className="col-5" style={{ textAlign: "right" }}>
                        <span className="text-150 text-success-d3 opacity-2">
                          {get(paymentObject(), "grant_total", 0).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="d-flex">
                  <strong className="mr-2">Notes:</strong>
                  <p>{data.notes}</p>
                </div>

                <div>
                  {invoiceStatus(data) === "UNPAID" && (
                    <form onSubmit={formik.handleSubmit}>
                      <div>
                        <InputText
                          id={"photograpername"}
                          label={"Photograper Name"}
                          placeholder={"Please enter a name"}
                          value={formik.values.photograperName}
                          error={
                            Boolean(formik.errors.photograperName) &&
                            formik.touched.photograperName
                          }
                          errorMessage={formik.errors.photograperName}
                          getValue={(value) =>
                            formik.setFieldValue("photograperName", value)
                          }
                          onBlur={() =>
                            formik.setFieldTouched("photograperName")
                          }
                        />
                      </div>
                      <div>
                        <InputSelect
                          selected={formik.values.shootType}
                          options={shootTypes}
                          label={"Shoot Type"}
                          placeholder={"Please select a shoot type."}
                          error={
                            Boolean(formik.errors.shootType) &&
                            formik.touched.shootType
                          }
                          errorMessage={formik.errors.shootType}
                          getValue={(value) =>
                            formik.setFieldValue("shootType", value)
                          }
                        />
                      </div>
                      {isAdmin && (
                        <div>
                          <InputSelect
                            selected={formik.values.payment_type}
                            options={paymentTypes}
                            label={"Payment Method"}
                            placeholder={"Please select a payment method."}
                            error={
                              Boolean(formik.errors.payment_type) &&
                              formik.touched.payment_type
                            }
                            errorMessage={formik.errors.payment_type}
                            getValue={(value) =>
                              formik.setFieldValue("payment_type", value)
                            }
                          />
                        </div>
                      )}

                      <div>
                        <InputText
                          id={"notes"}
                          label={"Notes"}
                          placeholder={"Enter notes"}
                          value={formik.values.notes}
                          error={
                            Boolean(formik.errors.notes) && formik.touched.notes
                          }
                          errorMessage={formik.errors.notes}
                          getValue={(value) =>
                            formik.setFieldValue("notes", value)
                          }
                          onBlur={() => formik.setFieldTouched("notes")}
                        />
                      </div>

                      <hr />

                      <div className="d-flex flex-row-reverse my-3">
                        {cards.length <= 0 && (
                          <div>
                            <Link
                              to="/profile/cards"
                              className="btn btn-sm btn-success"
                            >
                              Manage Cards
                            </Link>{" "}
                          </div>
                        )}
                        <div className="col-md-4 pl-0">
                          <InputSelect
                            selected={formik.values.pay_with}
                            options={cards}
                            label={"Pay with"}
                            placeholder={"Select a card"}
                            error={
                              Boolean(formik.errors.pay_with) &&
                              formik.touched.pay_with
                            }
                            errorMessage={formik.errors.pay_with}
                            getValue={(value) =>
                              formik.setFieldValue("pay_with", value)
                            }
                          />
                        </div>
                      </div>

                      <div>
                        <span className="text-secondary-d1 text-105">
                          Thank you for your business
                        </span>
                        <button
                          type={"submit"}
                          className="btn btn-info btn-bold px-4 float-right mt-3 mt-lg-0"
                          disabled={cards.length > 0 ? false : true}
                        >
                          Pay Now
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isLoading && <CustomLoader isNoteOpen={true} />}
      {alertOpen && (
        <AlertBar
          alertMessage={alertErrorMsg}
          alertType="error"
          onClose={() => setAlertOpen(false)}
        />
      )}
    </div>
  );
};
export default InvoiceComponent;
