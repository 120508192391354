import React, { Component } from "react";
import PublicRootElement from "./admin/component/PublicRootElement";
import {
  fetchBookingDetails,
  requestToCancel,
  confirmCancel,
} from "../services/private/InvoiceService";
import { connect } from "react-redux";
import InvoiceTable from "../components/tables/InvoiceTable";
// import CustomLoader from "../components/CustomLoader";
import AlertBar from "../components/core/Alert/AlertBar";
import Swal from "sweetalert2";
import { chain, find } from "lodash";
import store from "../store";
import { clearSlots, initLoadSlots } from "../store/actions/bookingAction";
// import { fetchUser } from "../store/actions/userAction";
import {
  addBookingDetails,
  removeBookingDetails,
} from "../store/actions/bookingDetailsAction";
import DateNavigator from "../components/core/dateNavigator/DateNavigator";
import moment from "moment";

// mui
import Skeleton from "@material-ui/lab/Skeleton";

// Custom imports
import { APP_NAME } from "../store/constance/actionTypes";
import PaginationComponent from "../components/PaginationComponent";
// import { fetchUserDetails } from "../services/UserService";

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bookingDetails: [],
      paginationDetail: {
        current_page: 1,
        first_page_url: "",
        from: 1,
        next_page_url: "",
        path: "",
        per_page: 5,
        prev_page_url: null,
        to: 5,
      },
      isLoading: false,
      tableHeader: [
        {
          align: "left",
          key: "id",
          label: "Reference ID",
        },
        {
          align: "left",
          key: "date",
          label: "Booked date",
        },
        {
          align: "left",
          key: "photographer_name",
          label: "Photographer name",
        },
        {
          align: "left",
          key: "shoot_type",
          label: "Shoot type",
        },
        {
          align: "left",
          key: "No of slots",
          label: "No of slots",
        },
        {
          align: "left",
          key: "status",
          label: "Status",
        },
        {
          align: "center",
          key: "action",
          label: "Action",
        },
      ],
      alertOpen: false,
      alertMessage: "",
      errors: {},
      alertType: "",
      date: moment(),
    };
  }

  componentDidMount = async () => {
    document.title = `Bookings - ${APP_NAME}`;
    this._fetchBookingDetails(this.state.paginationDetail.current_page);
  };

  _fetchBookingDetails = async (page) => {
    const { user } = this.props;
    this.setState({ isLoading: true });

    try {
      const reqDate = {
        user_id: user.id,
        page: page,
      };

      const bookingDetailsResponse = await fetchBookingDetails(reqDate);
      var bookings = [];

      let unEditableSlotFound = false;
      let unEditableSlotInvoiceId = "";

      if (bookingDetailsResponse.data.data.length > 0) {
        // console.log(`bookingDetailsResponse`, bookingDetailsResponse.data);

        bookings = bookingDetailsResponse.data.data;

        for (var i = 0; i < bookings.length; i++) {
          const booking = bookings[i];

          const slots = booking.slots_details;

          for (var s = 0; s < slots.length; s++) {
            const slot = slots[s];
            const calculatedTime = moment(slot.slot).fromNow();
            const inAday = calculatedTime.includes("in a day");
            const moreThanTwoDays = /in\s[0-9]\sdays+/g.test(calculatedTime);
            const passedDays = /[0-9]\sdays\sago+/g.test(calculatedTime);
            const inSomeHours = /in\s[0-9]\shours+/g.test(calculatedTime);

            bookings[i]["modifyBooking"] = true;

            if (passedDays) {
              // console.log("inAday", inAday);
              // console.log("moreThanTwoDays", moreThanTwoDays);
              // console.log("inSomeHours", inSomeHours);
              // console.log("");
              unEditableSlotFound = true;
              unEditableSlotInvoiceId = slot.booking_detail_id;
            }

            if (
              unEditableSlotFound &&
              unEditableSlotInvoiceId === slot.booking_detail_id
            ) {
              bookings[i]["modifyBooking"] = false;
            }
          }
        }
      }

      this.setState({
        bookingDetails: bookings,
        paginationDetail: {
          ...bookingDetailsResponse.data,
          data: [],
        },
        isLoading: false,
      });
    } catch (e) {}
  };

  reRenderApi = async () => {
    this._fetchBookingDetails(this.state.paginationDetail.current_page);
  };

  requestToCancel = async (data) => {
    console.log("requestToCancel");
    return;

    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          MOVE_TO_POINTS: "Move to points",
          REFUND: "Refund",
        });
      }, 100);
    });

    const { value: type } = await Swal.fire({
      title: `Are you sure? You want to cancel`,
      text: "Please select the cancel option",
      input: "radio",
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#5cb85c",
    });
    if (type) {
      // console.log(`type`, type);
      // return;

      this.setState({ isLoading: true });

      try {
        const response = await requestToCancel(data.id, {
          cancel_type: type,
        });
        this.reRenderApi().then((r) =>
          this.setState({
            isLoading: false,
            alertOpen: true,
            alertMessage: response.message,
            alertType: "success",
          })
        );
      } catch (e) {
        if (e.response && e.response.data && e.response.data.errors) {
          this.setState({
            isLoading: false,
            errors: e.response.data.errors,
            alertOpen: true,
            alertMessage: e.response.data.errors.message
              ? e.response.data.errors.message
              : "Something went wrong",
            alertType: "error",
          });
        } else {
          this.setState({
            isLoading: false,
            alertOpen: true,
            alertMessage: "Something went wrong",
            alertType: "error",
          });
        }
      }
    }
  };

  editBooking = async (data) => {
    const groupData = chain(data.slots_details)
      .groupBy("service_id")
      .map((value, key) => ({
        service_id: parseInt(key),
        slots: value.map((item) => ({
          price: item.price,
          shift_id: item.shift_id,
          slot: item.slot,
        })),
      }))
      .value();
    await store.dispatch(clearSlots([]));
    await store.dispatch(removeBookingDetails(null));
    await store.dispatch(initLoadSlots(groupData));
    await store.dispatch(addBookingDetails(data));
    this.props.history.push("/booking/" + groupData[0].service_id);
  };
  handleChangeDate = async (value) => {
    const { user } = this.props;
    this.setState({ isLoading: true });
    try {
      const reqDate = {
        user_id: user.id,
        start_date: moment(value).format("YYYY-MM-DD"),
        end_date: moment(value).add(1, "weeks").format("YYYY-MM-DD"),
      };
      const bookingDetailsResponse = await fetchBookingDetails(reqDate);

      this.setState({
        bookingDetails: bookingDetailsResponse.data,
        date: value,
        isLoading: false,
      });
    } catch (e) {
      this.setState({
        isLoading: false,
      });
    }
  };

  confirmCancel = async (data) => {
    const { user } = this.props;
    const { date } = this.state;

    // console.log("confirmCancel", data);

    for (var s = 0; s < data.slots_details.length; s++) {
      const slot = data.slots_details[s];
      const calculatedTime = moment(slot.slot).fromNow();
      // console.log("CT", calculatedTime);
      const inAday = calculatedTime.includes("in a day");
      const moreThanTwoDays = /in\s[0-9]\sdays+/g.test(calculatedTime);
      const inSomeHours = /in\s[0-9]\shours+/g.test(calculatedTime);

      // bookings[i]["modifyBooking"] = true;

      if (inAday || inSomeHours) {
        this.setState({
          isLoading: false,
          alertOpen: true,
          alertMessage: "Cannot cancel slots within 48 hours!",
          alertType: "error",
        });

        return;
      }
    }

    // console.log("not running");
    // return;

    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          MOVE_TO_POINTS: "Move to points",
          REFUND: "Refund",
        });
      }, 100);
    });

    const { value: type } = await Swal.fire({
      title: `Are you sure? You want to cancel`,
      text: "Please select the cancel option",
      input: "radio",
      inputOptions: inputOptions,
      inputValidator: (value) => {
        if (!value) {
          return "You need to choose something!";
        }
      },
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#5cb85c",
    });

    if (!type) {
      return;
    }

    const swal = await Swal.fire({
      title: "Are you sure?",
      text: "You want to confirm the cancellation. You cannot revert this process!",
      icon: "success",
      showCloseButton: true,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: `Cancel`,
      confirmButtonText: `Confirm`,
      confirmButtonColor: "#5cb85c",
    });

    // console.log(`final`);
    // return;

    if (swal.value) {
      try {
        const reqData = {
          sub_total: find(data.payments, { payment_status: "SUCCESS" })
            .sub_total,
          discount: find(data.payments, { payment_status: "SUCCESS" }).discount,
          grant_total: find(data.payments, { payment_status: "SUCCESS" })
            .grant_total,
          booked_by: `cancel by ${user.firstname}`,
          cancel_type: type,
          dateTime: moment().format("YYYY-MM-DD HH:ss"),
        };

        const response = await confirmCancel(data.id, reqData);
        await this._fetchBookingDetails(
          this.state.paginationDetail.current_page
        );

        this.setState({
          alertOpen: true,
          alertMessage: response.message,
          alertType: "success",
        });
      } catch (e) {
        this.setState({
          isLoading: false,
          alertOpen: true,
          alertMessage: "Something went wrong!",
          alertType: "error",
        });
      }
    }
  };

  render() {
    const {
      bookingDetails,
      tableHeader,
      isLoading,
      alertOpen,
      alertMessage,
      alertType,
      date,
      paginationDetail,
    } = this.state;
    return (
      <PublicRootElement>
        <div className="container">
          {isLoading ? (
            <div>
              <Skeleton
                variant="rect"
                width={300}
                height={35}
                style={{ marginBottom: 5 }}
              />
              <Skeleton
                variant="rect"
                height={50}
                style={{ marginBottom: 5 }}
              />
              <Skeleton
                variant="rect"
                height={150}
                style={{ marginBottom: 5 }}
              />
            </div>
          ) : (
            <>
              {/*<DateNavigator
                disablePast={false}
                disableFuture={false}
                type={"WEEK"}
                isShowDate={true}
                date={date}
                onChangeDate={(date) => this.handleChangeDate(date)}
              />*/}
              {bookingDetails && (
                <InvoiceTable
                  goToDetails={(data) =>
                    this.props.history.push("/invoices/" + data.id)
                  }
                  confirmCancel={(data) => this.confirmCancel(data)}
                  requestToCancel={(data) => this.requestToCancel(data)}
                  editBooking={(data) => this.editBooking(data)}
                  header={tableHeader}
                  bookingDetails={bookingDetails}
                  handleState={(data) => this.setState(data)}
                  direct={true}
                >
                  {paginationDetail && paginationDetail.links && (
                    <PaginationComponent
                      data={paginationDetail}
                      setCurrentPage={(page) => this._fetchBookingDetails(page)}
                    />
                  )}
                </InvoiceTable>
              )}{" "}
            </>
          )}
        </div>

        {/*{isLoading && <CustomLoader isNoteOpen={true} />}*/}
        {alertOpen && (
          <AlertBar
            alertMessage={alertMessage}
            alertType={alertType}
            onClose={() => this.setState({ alertOpen: false })}
          />
        )}
      </PublicRootElement>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
};
export default connect(mapStateToProps, null)(Invoice);
