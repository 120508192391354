import React from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import moment from "moment";
import { find, isEmpty, isEqual } from "lodash";
import "../../theme/css/invoiceTable.css";
import RolePermission from "../RolePermission/RolePermission";

// Custom imports
import {
  cancelBookingByAdmin,
  cancelSlots,
} from "../../services/private/InvoiceService";

function TableHead({ header }) {
  return (
    <thead>
      <tr>
        {header.map((header, index) => {
          return (
            <th
              style={{ textAlign: header.align, border: "none" }}
              scope="col"
              key={index}
            >
              {header.label}
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

const ManageCardsTable = ({
  header,
  cards,
  user,
  handleChangeCardActiveStatus,
  removeCard,
  children,
}) => {
  return (
    <div>
      <table className="table">
        <TableHead header={header} />
        <tbody>
          {cards.map((card, index) => {
            return (
              <tr key={index}>
                <td style={{ textAlign: "left" }}>{card.id}</td>
                {/* <td style={{ textAlign: "left" }}>{card.card_type}</td>*/}
                <td style={{ textAlign: "left" }}>
                  ************{card.last_digits}
                </td>
                <td style={{ textAlign: "left" }}>
                  <div className="custom-control custom-switch">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id={"customSwitch1" + index}
                      checked={card.default_card}
                      onChange={() =>
                        handleChangeCardActiveStatus(
                          card.id,
                          !card.default_card
                        )
                      }
                    />
                    <label
                      class="custom-control-label"
                      for={"customSwitch1" + index}
                    >
                      {/*  Toggle this switch element*/}
                    </label>
                  </div>
                </td>

                <td
                  className="td-actions"
                  style={{
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <button
                    className="btn btn-sm btn-danger"
                    onClick={() => removeCard(card.last_digits)}
                  >
                    Remove
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="d-flex justify-content-center my-3">{children}</div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, membership } = state;
  return { user, membership };
};

export default connect(mapStateToProps, null)(ManageCardsTable);
