import React, { Component } from "react";

// others
import Swal from "sweetalert2";

// components
import PublicRootElement from "./admin/component/PublicRootElement";
import ManageCardTable from "../components/tables/ManageCardTable";
import AlertBar from "../components/core/Alert/AlertBar";
import CustomDialog from "../components/core/CustomDialog";
import PaginationComponent from "../components/PaginationComponent";
import CreditCardForm from "../components/forms/CreditCardForm";

// state
import { connect } from "react-redux";
import store from "../store";
import { clearSlots, initLoadSlots } from "../store/actions/bookingAction";

// Services
import {
  allCards,
  addNewCard,
  removeCard,
  changeCardActiveStatus,
} from "../services/private/CardService";

// mui
import Skeleton from "@material-ui/lab/Skeleton";

// Custom imports
import { APP_NAME } from "../store/constance/actionTypes";

class ManageCards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      paginationDetail: {
        current_page: 1,
        first_page_url: "",
        from: 1,
        next_page_url: "",
        path: "",
        per_page: 5,
        prev_page_url: null,
        to: 5,
      },
      isLoading: false,
      isOpen: false,
      isAdd: false,
      isEdit: false,
      tableHeader: [
        {
          align: "left",
          key: "id",
          label: "SN#",
        },
        /*{
          align: "left",
          key: "card_type",
          label: "Card type",
        },*/
        {
          align: "left",
          key: "card_number",
          label: "Card number",
        },
        {
          align: "left",
          key: "default_card",
          label: "Default Card",
        },
        {
          align: "center",
          key: "action",
          label: "Action",
        },
      ],
      alertOpen: false,
      alertMessage: "",
      errors: {},
      alertType: "",
      errros: [],
    };
  }

  componentDidMount = async () => {
    document.title = `Bookings - ${APP_NAME}`;
    this._fetchCards();
  };

  _fetchCards = async (page) => {
    try {
      this.setState({ isLoading: true });

      const { user } = this.props;

      var requestParams = {
        user_id: user.id,
      };

      const _customer = this.props.customer;

      if (_customer.customer && Object.keys(_customer.customer).length > 0) {
        requestParams = { ...requestParams, user_id: _customer.customer.id };
      }

      const res = await allCards(requestParams.user_id);

      this.setState({ isLoading: false, cards: res.data });
    } catch (e) {
      console.log(`e`, e);
    }
  };

  handleClose = async () => {
    this.setState({ isOpen: false, isAdd: false, isEdit: false, errors: {} });
  };

  addCard = async (payload) => {
    try {
      this.handleClose();
      this.setState({ isLoading: true });

      const { user } = this.props;

      var requestParams = {
        user_id: user.id,
      };

      const _customer = this.props.customer;

      if (_customer.customer && Object.keys(_customer.customer).length > 0) {
        requestParams = { ...requestParams, user_id: _customer.customer.id };
      }

      const isFirstCard = this.state.cards.length > 0 ? false : true;

      await addNewCard(requestParams.user_id, {
        ...payload,
        active: isFirstCard,
      });

      this._fetchCards();
    } catch (error) {
      console.log(`error`, error);
    }
  };

  handleChangeCardActiveStatus = async (id, status) => {
    try {
      const swalRes = await Swal.fire({
        title: "Do you want to change the active status?",
        showCancelButton: true,
        confirmButtonText: "Save",
      });

      if (!swalRes.isConfirmed) return;

      this.setState({ isLoading: true });

      const { user } = this.props;

      var requestParams = {
        user_id: user.id,
        status,
      };

      const _customer = this.props.customer;

      if (_customer.customer && Object.keys(_customer.customer).length > 0) {
        requestParams = { ...requestParams, user_id: _customer.customer.id };
      }

      const res = await changeCardActiveStatus(id, requestParams);

      this._fetchCards();
    } catch (error) {
      console.log(`error`, error);
    }
  };

  removeCard = async (cardNumber) => {
    try {
      const swalRes = await Swal.fire({
        title: "Are you sure, You want to delete the card?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      });

      if (!swalRes.isConfirmed) return;

      const card_number = cardNumber.replace(/\*/g, "");
      // console.log(`card_number`, card_number);
      // return;

      this.handleClose();
      this.setState({ isLoading: true });

      const { user } = this.props;

      var requestParams = {
        user_id: user.id,
      };

      const _customer = this.props.customer;

      if (_customer.customer && Object.keys(_customer.customer).length > 0) {
        requestParams = { ...requestParams, user_id: _customer.customer.id };
      }

      const isFirstCard = this.state.cards.length > 0 ? false : true;

      await removeCard(requestParams.user_id, {
        card_number,
      });

      this._fetchCards();
    } catch (error) {
      console.log(`error`, error);
    }
  };

  render() {
    const {
      cards,
      tableHeader,
      isLoading,
      isOpen,
      isAdd,
      isEdit,
      alertOpen,
      alertMessage,
      alertType,
      date,
      paginationDetail,
      errors,
    } = this.state;
    return (
      <PublicRootElement>
        <div className="container">
          {isLoading ? (
            <div>
              <Skeleton
                variant="rect"
                width={300}
                height={35}
                style={{ marginBottom: 5 }}
              />
              <Skeleton
                variant="rect"
                height={50}
                style={{ marginBottom: 5 }}
              />
              <Skeleton
                variant="rect"
                height={150}
                style={{ marginBottom: 5 }}
              />
            </div>
          ) : (
            <>
              <div className="text-right mb-5">
                <button
                  className="btn btn-success"
                  onClick={() =>
                    this.setState({
                      isOpen: true,
                      isAdd: true,
                      isEdit: false,
                      errors: {},
                    })
                  }
                >
                  Add
                </button>
              </div>
              {cards && (
                <ManageCardTable
                  goToDetails={(data) =>
                    this.props.history.push("/invoices/" + data.id)
                  }
                  requestToCancel={(data) => this.requestToCancel(data)}
                  editBooking={(data) => this.editBooking(data)}
                  header={tableHeader}
                  cards={cards}
                  handleState={(data) => this.setState(data)}
                  handleChangeCardActiveStatus={
                    this.handleChangeCardActiveStatus
                  }
                  removeCard={this.removeCard}
                >
                  {paginationDetail && paginationDetail.links && (
                    <PaginationComponent
                      data={paginationDetail}
                      setCurrentPage={(page) => this._fetchCards(page)}
                    />
                  )}
                </ManageCardTable>
              )}{" "}
            </>
          )}
        </div>

        {isOpen && (
          <CustomDialog closeDialog={this.handleClose} isNoteOpen={isOpen}>
            {isAdd && (
              <CreditCardForm
                initialValues={{}}
                errors={errors}
                handleClose={this.handleClose}
                getFormValue={this.addCard}
              />
            )}
            {isEdit && (
              <CreditCardForm
                initialValues={{}}
                errors={errors}
                handleClose={this.handleClose}
                getFormValue={this.addCard}
              />
            )}
          </CustomDialog>
        )}

        {/*{isLoading && <CustomLoader isNoteOpen={true} />}*/}
        {alertOpen && (
          <AlertBar
            alertMessage={alertMessage}
            alertType={alertType}
            onClose={() => this.setState({ alertOpen: false })}
          />
        )}
      </PublicRootElement>
    );
  }
}

const mapStateToProps = (state) => {
  const { user, customer } = state;
  return { user, customer };
};
export default connect(mapStateToProps, null)(ManageCards);
